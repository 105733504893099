import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Stack } from '@mui/system';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // For form validation
import nraLogo from "../../../../../Assets/Partner/NRA/NRA-logo-300x168.png";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import "./index.scss";
import PartnerLogo from '../../../../../Components/PartnerLogo';
import useFetch from 'use-http';
import { HIDE_PARTNER_LOGO } from '../../Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../../../Assets/Affiliates/AllAccessService/All_Access_Serivce.png'
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';

export default function AllAccessService() {
    const { pathname } = useLocation();
    const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);
    const navigate = useNavigate();
    const handleNavigate = (route: string, location: string | null = null) => {
        if (location === '_blank') {
            window.open(route, location);
        } else {
            navigate(route);
        }
    };

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    // Formik Initial Values and Validation Schema
    const initialValues = {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        customerNotes: '',
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required')
            .min(2, 'First name must be at least 2 characters')
            .max(50, 'First name must be at most 50 characters')
            .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
        lastName: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        Zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
        phone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        repName: Yup.string().required('Required'),
        repphone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        repId: Yup.string().required('Required'),
        notes: Yup.string().required('Required'),
        customerNotes: Yup.string(),
    });

    return (
        <Box id='template6-wrapper'>
            <Grid container className='mobile-center' sx={{ maxWidth: '1024px', margin: '45px auto', }}>
                <Grid className='mobile-center' item xs={12} md={6} sx={{ marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
                    <CompanyLogo className=''></CompanyLogo>
                </Grid>
                <Grid className='mobile-center' item xs={12} md={6} sx={{
                    textAlign: 'right',
                    justifyContent: 'right',
                }}>
                    {/* {HIDE_PARTNER_LOGO.includes(pathname) ? null : <PartnerLogo partnerId={PartnerInfo.partnerId} campaignId={PartnerInfo.campaignId}></PartnerLogo>} */}
                </Grid>
            </Grid>

            <Box className='all-access-service-contact-container'>
                <Typography className='white-text'>
                    No more waiting on hold. All Access works with all the major internet providers,
                    so we can provide you the best deals and exclusive offers in your area.
                    We take care of negotiating rates and scheduling so you don't have to lift a finger.
                </Typography>
                <Typography className='white-text'>Give us a call today.</Typography>
                <Box className='all-access-service-submit-btn' sx={{ textAlign: 'center' }}>
                    <Button className='order-entry-submit' type="submit" variant="contained">Submit</Button>
                </Box>
            </Box>

            <Box>
                <Typography className='all-access-service-form-title'>Dont Wait. Get Connected Today!</Typography>

                <Container maxWidth={"md"} className='all-access-service-form-container'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            try {
                                // Add the hidden values to the submission data
                                const submissionData = {
                                    ...values,
                                    partnerCompanyId: '1265',
                                    partnerCampaignId: '2759',
                                    clientInfo: {
                                        url: window.location.href,
                                    }
                                };

                                const postResponse = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                if (postResponse.code === 'OK') {
                                    const parsedContent = JSON.parse(postResponse.content);

                                    const newLeadId = parsedContent.details.output;
                                    const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                    setLeadCreationStatus(true);

                                    console.log("New ID: " + newLeadId);

                                    //navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });

                                    console.log("Request sent successfully:", postResponse);
                                    resetForm();
                                } else {
                                    console.log("Response code not OK: " + postResponse.code);
                                }
                            } catch (error) {
                                // Handle errors (e.g., show an error message)
                                console.error("Error sending request:", error);
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => ( // Access Formik state here
                            <Form>
                                <Box className='fields-container perfect-vision'>
                                    <Field
                                        as={TextField}
                                        className='half-width left-field'
                                        name="firstName"
                                        label="First Name"
                                        error={touched.firstName && Boolean(errors.firstName)} // Check if touched and error exists
                                        helperText={touched.firstName && errors.firstName} // Show error message if touched
                                    />

                                    <Field
                                        as={TextField}
                                        className='half-width right-field'
                                        name="lastName"
                                        label="Last Name"
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                    />

                                    <Field
                                        as={TextField}
                                        className='full-width'
                                        name="phone"
                                        label="Phone"
                                        onChange={(event: { target: { value: any; }; }) => {
                                            const inputValue = event.target.value.replace(/\D/g, '');

                                            let formattedValue = '';
                                            if (inputValue.length > 0) {
                                                formattedValue = '(' + inputValue.substring(0, 3);
                                                if (inputValue.length > 3) {
                                                    formattedValue += ') ' + inputValue.substring(3, 6);
                                                }
                                                if (inputValue.length > 6) {
                                                    formattedValue += '-' + inputValue.substring(6, 10);
                                                }
                                            }

                                            setFieldValue('phone', formattedValue);
                                        }}
                                        onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                            const clipboardData = event.clipboardData;
                                            const pastedData = clipboardData.getData('text');
                                            const cleanedData = pastedData.replace(/\D/g, '');
                                            const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                            setFieldValue('phone', formattedValue);
                                            event.preventDefault();
                                        }}
                                        error={touched.phone && Boolean(errors.phone)}
                                        helperText={touched.phone && errors.phone}
                                    />

                                    <Field
                                        as={TextField}
                                        className='full-width'
                                        name="address"
                                        label="New Address"
                                        error={touched.address && Boolean(errors.address)}
                                        helperText={touched.address && errors.address}
                                    />

                                    <Field
                                        as={TextField}
                                        className='half-width left-field'
                                        name="city"
                                        label="City"
                                        error={touched.city && Boolean(errors.city)}
                                        helperText={touched.city && errors.city}
                                    />

                                    <Field
                                        as={TextField}
                                        className='half-width right-field'
                                        name="state"
                                        label="State / Province"
                                        error={touched.state && Boolean(errors.state)}
                                        helperText={touched.state && errors.state}
                                    />

                                    <Field
                                        as={TextField}
                                        className='full-width'
                                        name="zip"
                                        label="Zip"
                                        error={touched.zip && Boolean(errors.zip)}
                                        helperText={touched.zip && errors.zip}
                                    />

                                    <Field
                                        as={TextField}
                                        className='full-width'
                                        name="customerNotes"
                                        label="Comments"
                                        error={touched.customerNotes && Boolean(errors.customerNotes)}
                                        helperText={touched.customerNotes && errors.customerNotes}
                                    />

                                    <Box className='all-access-service-submit-btn' sx={{ textAlign: 'center' }}>
                                        <Button className='order-entry-submit' type="submit" variant="contained">Submit</Button>
                                    </Box>

                                    <Box>
                                        <Typography className='bottom-disclaimer-text'>By clicking “Submit” I hereby acknowledge the customer has provided consent for Safe Haven ADT to obtain their consumer credit report, charge their card the applicable activation fee, and have requested opt-in for a callback from Safe Haven ADT should the call be disconnected during the transfer process</Typography>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </Box>

            <Box className='all-access-service-contact-container'>
                <Typography className='white-text'>
                    No more waiting on hold. All Access works with all the major internet providers,
                    so we can provide you the best deals and exclusive offers in your area.
                    We take care of negotiating rates and scheduling so you don't have to lift a finger.
                </Typography>
                <Typography className='white-text'>Give us a call today.</Typography>
                <Box className='all-access-service-submit-btn' sx={{ textAlign: 'center' }}>
                    <Button className='order-entry-submit' type="submit" variant="contained">Submit</Button>
                </Box>
            </Box>

            <Box className='all-access-footer'>
                <Container>
                    <Box
                        component='img'
                        sx={{
                            maxHeight: '120px',
                            width: 'auto',
                        }}
                        alt='Logo Image Not Found'
                        src={logo}
                    />
                    <Stack className='all-access-footer-contact-info'>
                        <Typography sx={{ fontSize: '18px' }}>Contact</Typography>
                        <Typography>
                            <PlaceIcon></PlaceIcon> 520 E 19th Ave
                            <br></br>
                            N Kansas City, MO 64116
                        </Typography>
                        <br></br>
                        <Typography><CallIcon sx={{ color: 'white' }}></CallIcon>877.343.5579</Typography>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
} 