import { Box, Container, Stack } from '@mui/system';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputLabel, Link, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // For form validation
import yardSign from "../../../../../Assets/Partner/Paddio/yard-sign-1-img.jpg";
import planIcons from "../../../../../Assets/Partner/Paddio/plans-icons.png";
import CompanyLogo from '../../../../../Components/Header/CompanyLogo';
import icon1 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-1.jpg";
import icon2 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-2.jpg";
import icon3 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-3.jpg";
import icon4 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-4.jpg";
import icon5 from "../../../../../Assets/Partner/Paddio/plans-individual-icon-5.jpg";
import vuVoucher from "../../../../../Assets/Templates/1000-instant-rebate-VU-2.png";
import army from "../../../../../Assets/Templates/army.jpg";
import "./index.scss";
import SwiperCarousel from '../../../../../Components/SwiperCarousel';
import * as Constants from '../../../../../Constants/Pages';
import { isMobile, isTablet } from 'react-device-detect';
import PartnerLogo from '../../../../../Components/PartnerLogo';
import useFetch from 'use-http';
import { HIDE_PARTNER_LOGO } from '../../Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { STATE_LIST } from '../../../../../Constants';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import panelBillboard1 from "../../../../../Assets/Templates/panel-image-cropped.png";
import panelBillboard2 from "../../../../../Assets/Templates/banner-img-1136x666-cropped.jpg";
import voucher500 from "../../../../../Assets/Templates/voucher-500.png";
import { useEffect, useMemo, useRef, useState } from 'react';
import Billboard from '../../Components/Billboard';
import LogoBanner from '../../Components/LogoBanner';
import PartnerFooter from '../../Components/PartnerFooter';
import { getPhoneNumberForPath } from '../../Functions';
import { DateValidationError } from '@mui/x-date-pickers/models/validation';

interface propsWrapper {
    partnerInfo: PartnerInfo
}

export default function TemplateFour(props: propsWrapper) {
    const { partnerInfo } = props;
    const isMobileOrTablet = isMobile || isTablet;
    const { pathname } = useLocation();
    const [voucherImage, setVoucherImage] = useState(voucher500);
    const formRef = useRef<HTMLDivElement>(null);
    const [phoneNumber, setPhoneNumber] = useState('+1 (877) 664-0673');
    const [billboardText, setBillboardText] = useState('Keeping your home & family safe');
    const [billboardImage, setBillboardImage] = useState(panelBillboard1);
    const [billboardButtonClass, setBillboardButtonClass] = useState('blue-rounded-btn');
    const [grayFormBanner, setGrayFormBanner] = useState(false);
    const [logoBannerType, setLogoBannerType] = useState('default');
    const [error, setDatePickerError] = useState<DateValidationError | null>(null);
    const [leadCreationStatus, setLeadCreationStatus] = useState<boolean | null>(null);
    const navigate = useNavigate();
    const handleNavigate = (route: string, location: string | null = null) => {
        if (location === '_blank') {
            window.open(route, location);
        } else {
            navigate(route);
        }
    };

    const errorMessage = useMemo(() => {
        switch (error) {
            case 'maxDate':
            case 'minDate': {
                return 'Please select a date in the first quarter of 2022';
            }
            case 'invalidDate': {
                return 'Your date is not valid';
            }
            default: {
                return '';
            }
        }
    }, [error]);

    let apiKey = process.env.REACT_APP_API__ACCESS_KEY
        ? process.env.REACT_APP_API__ACCESS_KEY
        : '';

    const options = {
        headers: {
            'Content-Type': 'application/json',
            Connection: 'keep-alive',
            'Accept-Encoding': 'gzip, deflate, br',
            'X-API-Key': apiKey,
        },
        cacheLife: 1000,
    };

    const { post, response, loading } = useFetch(
        process.env.REACT_APP_API,
        options
    );

    // Names align with affiliate api parms
    const initialValues = {
        firstName: '',
        LastName: '',
        email: '',
        phone: '',
        address: '',
        City: '',
        State: '',
        Zip: '',
        adtCustomerStatus: '',
        customerNotes: '',
        securityInterests: [],
        expectedClosingDate: null,
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required')
            .min(2, 'First name must be at least 2 characters')
            .max(50, 'First name must be at most 50 characters')
            .matches(/^[a-zA-Z '-]+$/, 'First name can only contain letters, hyphens, apostrophes, and spaces'),
        LastName: Yup.string().required('Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        phone: Yup.string().required('Required')
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format'),
        address: Yup.string().required('Required'),
        City: Yup.string().required('Required'),
        State: Yup.string().required('Required'),
        Zip: Yup.string().matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format').required('Required'),
        adtCustomerStatus: Yup.string()
            .required('Required'),
        expectedClosingDate: Yup.date()
            .required("Required"),
        securityInterests: Yup.array()
            .min(1, 'Please select at least one security interest')
    });

    useEffect(() => {
        if (pathname === '/partners/my-vu') {
            setLogoBannerType('default')
            setBillboardImage(panelBillboard2);
        }
    }, []);

    useEffect(() => {
        const newPhoneNumber = getPhoneNumberForPath(pathname);
        setPhoneNumber(newPhoneNumber);

        if (pathname === '/partners/my-vu') {
            setBillboardButtonClass('blue-squared')
        }

    }, [pathname]);

    const handleScrollToForm = () => {
        if (formRef.current) {
            const elementTop = formRef.current.getBoundingClientRect().top;
            const offsetPosition = elementTop + window.pageYOffset - (window.innerHeight * 0.2);

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Box id='template4-wrapper'>
            <Container maxWidth={'xl'}>
                <LogoBanner partnerInfo={partnerInfo} variant={logoBannerType} phoneNumber={phoneNumber}></LogoBanner>
            </Container>

            <Billboard
                text={billboardText}
                imageUrl={billboardImage}
                buttonClass={billboardButtonClass}
                buttonText="GET STARTED NOW"
                onClick={() => { handleScrollToForm() }}
            />

            <Container maxWidth={'lg'} sx={{}}>
                <Grid container sx={{ padding: '50px 0' }} columnSpacing={{ sm: 3 }}>
                    <Grid item xs={12} md={6}
                        sx={{
                            display: { xs: 'flex', sx: 'initial' },
                            alignItems: { xs: 'center', sx: 'initial' },
                            justifyContent: { xs: 'center', sx: 'initial' },
                        }}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                            }}
                            src={yardSign}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className='partnership-header' sx={{ textAlign: { xs: 'center', md: 'initial' }, marginTop: { xs: '32px', sm: '0' } }}>
                            A Powerful Partnership
                        </Typography>

                        <Grid className='mobile-center' container sx={{ maxWidth: '1200px', margin: 'auto', paddingBottom: '24px' }}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                className='mobile-center'
                                sx={{
                                    marginBottom: { xs: '32px', sm: '0' },
                                    display: { xs: 'flex', sx: 'initial' },
                                    alignItems: { xs: 'center', sx: 'initial' },
                                    justifyContent: { xs: 'center', sx: 'initial' },
                                }}
                            >
                                <CompanyLogo maxHeight={"100%"} maxWidth={"100%"} marginLeft={"10%"}></CompanyLogo>
                            </Grid>
                            <Grid
                                item xs={12}
                                md={6}
                                className='mobile-center'
                                sx={{
                                    display: { xs: 'flex', sx: 'initial' },
                                    alignItems: { xs: 'center', sx: 'initial' },
                                    justifyContent: { xs: 'center', sx: 'initial' },
                                }}
                            >
                                <PartnerLogo partnerInfo={partnerInfo}></PartnerLogo>
                            </Grid>
                        </Grid>

                        <Typography sx={{
                            fontSize: "18px",
                            marginBottom: '24px',
                            color: '#545859'
                        }}>
                            Safe Haven presents a special opportunity for Paddio borrowers, enabling them to safeguard their home and loved ones with the renowned security monitoring service provided by ADT, the most trusted name in security. This comprehensive security solution not only brings peace of mind by protecting life and property, but it also incorporates intelligent home features that make using the system more convenient than ever before.
                        </Typography>
                        <Typography sx={{
                            fontSize: "18px",
                            marginBottom: '24px',
                            color: '#545859'
                        }}>
                            With a widespread presence in over 100 metropolitan locations across the US, Safe Haven takes pride in serving communities nationwide. It boasts an exceptional A+ rating with the prestigious Better Business Bureau, showcasing its commitment to delivering top-notch service and customer satisfaction.
                        </Typography>
                        <Typography sx={{
                            fontSize: "18px",
                            marginBottom: '24px',
                            color: '#545859'
                        }}>
                            Safe Haven’s SmartHome Advantage system offers an all-in-one security solution by integrating ADT monitoring. Through this system, customers gain easy access to their security system via a web browser or phone app, which even allows for remote video access, providing an extra layer of convenience and peace of mind.
                        </Typography>
                    </Grid>
                </Grid>

                <Box sx={{ marginBottom: '32px' }}>
                    <Typography className='carousel-header'>Monitoring as low as $45<sup>99+</sup></Typography>
                    <SwiperCarousel
                        slides={Constants.PADDIO_PARTNER_PAGE}
                        type={'paddioPartnerPage'}
                    ></SwiperCarousel>
                </Box>

                <Box
                    component="img"
                    sx={{
                        maxWidth: "100%",
                        height: "auto",
                        display: { xs: 'none', md: 'block' }
                    }}
                    src={planIcons}
                />

                <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "300px",
                            height: "auto",
                            margin: '12px auto'
                        }}
                        src={icon1}
                    />
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "300px",
                            height: "auto",
                            margin: '12px auto'
                        }}
                        src={icon2}
                    />
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "300px",
                            height: "auto",
                            margin: '12px auto'
                        }}
                        src={icon3}
                    />
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "300px",
                            height: "auto",
                            margin: '12px auto'
                        }}
                        src={icon4}
                    />
                    <Box
                        component="img"
                        sx={{
                            maxWidth: "300px",
                            height: "auto",
                            margin: '12px auto'
                        }}
                        src={icon5}
                    />
                </Stack>

                <Grid container sx={{ paddingTop: '50px' }} columnSpacing={{ md: 7 }}>
                    <Grid item xs={12} sm={6}>
                        <Stack>
                            <Typography className='voucher-blue-text'>
                                With our Veterans United partnership, you are eligible to receive a $1000 Instant Upgrade* for additional equipment.
                            </Typography>
                            <Typography className='voucher-body-text'>
                                Ready to customize an ADT plan that works for you? Give us a call at{" "}
                                <Link href={`tel:${phoneNumber}`} underline="none" className='voucher-body-phone'>{phoneNumber}</Link>{" "}
                                to talk to an ADT Specialist today – or fill out the form to have us contact you.
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    maxHeight: '350px',
                                }}
                                src={vuVoucher}
                            />
                            <Typography className='voucher-disclaimer-text'>
                                *With $129.00 Customer Installation Charge and purchase of alarm monitoring services. Early termination fees apply. See Important Terms and Conditions to this offer below.
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    marginTop: '35px',
                                    maxWidth: "100%",
                                    height: "auto",
                                }}
                                src={army}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} ref={formRef}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                try {
                                    const submissionData = {
                                        ...values,
                                        securityInterests: values.securityInterests.map((interest) => interest).join(', '),
                                        partnerCompanyId: partnerInfo.PartnerId,
                                        partnerCampaignId: partnerInfo.CampaignId,
                                        clientInfo: {
                                            url: window.location.href,
                                        }
                                    };

                                    const postResponse = await post("/Partner/CreateZohoLeadByPartnerId", submissionData);

                                    if (postResponse.code === 'OK') {
                                        const parsedContent = JSON.parse(postResponse.content);

                                        const newLeadId = parsedContent.details.output;
                                        const userMessage = JSON.parse(parsedContent.details.userMessage[0]);

                                        setLeadCreationStatus(true);

                                        console.log("New ID: " + newLeadId);

                                        navigate('/partners/confirmation', { state: { newLeadId, partnerInfo } });

                                        console.log("Request sent successfully:", postResponse);
                                        resetForm();
                                    } else {
                                        console.log("Response code not OK: " + postResponse.code);
                                    }
                                } catch (error) {
                                    console.error("Error while sending request:", error);
                                } finally {
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({ errors, touched, values, setFieldValue, isSubmitting, setFieldTouched, setErrors, validateField }) => (
                                <Form>
                                    <Box className='fields-container freedom-mortgage'>
                                        <Field type="hidden" name="Partner_Company1" />
                                        <Field type="hidden" name="Campaign1" />

                                        <Field
                                            as={TextField}
                                            className='half-width left-field freedom-mortgage-field mini-form'
                                            name="firstName"
                                            label="First Name *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.firstName}
                                            onChange={(event: any) => setFieldValue("firstName", event.target.value)}
                                            error={touched.firstName && Boolean(errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                        />


                                        <Field
                                            as={TextField}
                                            className='half-width right-field freedom-mortgage-field mini-form'
                                            name="LastName"
                                            label="Last Name *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.LastName}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("LastName", event.target.value)}
                                            error={touched.LastName && Boolean(errors.LastName)}
                                            helperText={touched.LastName && errors.LastName}
                                        />

                                        <Field
                                            as={TextField}
                                            className='full-width freedom-mortgage-field mini-form'
                                            name="email"
                                            label="Email *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.email}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("email", event.target.value)}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />

                                        <Field
                                            as={TextField}
                                            className='half-width left-field freedom-mortgage-field mini-form'
                                            name="phone"
                                            label="Phone Number *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.phone}
                                            onChange={(event: { target: { value: any; }; }) => {
                                                const inputValue = event.target.value.replace(/\D/g, '');

                                                let formattedValue = '';
                                                if (inputValue.length > 0) {
                                                    formattedValue = '(' + inputValue.substring(0, 3);
                                                    if (inputValue.length > 3) {
                                                        formattedValue += ') ' + inputValue.substring(3, 6);
                                                    }
                                                    if (inputValue.length > 6) {
                                                        formattedValue += '-' + inputValue.substring(6, 10);
                                                    }
                                                }

                                                setFieldValue('phone', formattedValue);
                                            }}
                                            onPaste={(event: { clipboardData: any; preventDefault: () => void; }) => {
                                                const clipboardData = event.clipboardData;
                                                const pastedData = clipboardData.getData('text');
                                                const cleanedData = pastedData.replace(/\D/g, '');
                                                const formattedValue = cleanedData.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                                setFieldValue('phone', formattedValue);
                                                event.preventDefault();
                                            }}
                                            error={touched.phone && Boolean(errors.phone)}
                                            helperText={touched.phone && errors.phone}
                                        />

                                        <FormControl
                                            className='half-width right-field'
                                            sx={{
                                                width: '100%',
                                                minHeight: '65px',
                                                "& div>label": {
                                                    color: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    className='full-width freedom-mortgage-field mini-form'
                                                    sx={{
                                                        "& div>div>button>svg": {
                                                            zIndex: 9,
                                                            color: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : (touched.expectedClosingDate && !Boolean(errors.expectedClosingDate)) ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                        "& div, p.MuiFormHelperText-root": {
                                                            color: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : 'rgba(0, 0, 0, 0.87)',
                                                        },
                                                        "& div>fieldset": {
                                                            borderColor: (touched.expectedClosingDate && Boolean(errors.expectedClosingDate)) ? '#d32f2f!important' : 'rgba(0, 0, 0, 0.87)',
                                                        },

                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            helperText: (touched.expectedClosingDate && errors.expectedClosingDate),
                                                            onBlur: (value) => {
                                                                setFieldTouched('expectedClosingDate', true);
                                                            },
                                                        },
                                                    }}
                                                    onAccept={(newValue) => {
                                                        console.log("onAccept: ", newValue);
                                                        setFieldValue("expectedClosingDate", newValue);
                                                        console.log("values.expectedClosingDate: ", values.expectedClosingDate);
                                                    }}
                                                    disablePast
                                                    label="Closing Date"
                                                    format="MM/DD/YYYY"
                                                    value={values.expectedClosingDate}
                                                    onChange={(value) => {
                                                        console.log(value)
                                                        setFieldValue("expectedClosingDate", value);
                                                        console.log("values.expectedClosingDate2: ", values.expectedClosingDate);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>

                                        <Field
                                            as={TextField}
                                            className='full-width freedom-mortgage-field mini-form'
                                            name="address"
                                            label="Street Address *"
                                            value={values.address}
                                            sx={{ minHeight: '65px' }}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("address", event.target.value)}
                                            error={touched.address && Boolean(errors.address)}
                                            helperText={touched.address && errors.address}
                                        />

                                        <Field
                                            as={TextField}
                                            className='half-width left-field freedom-mortgage-field mini-form'
                                            name="City"
                                            label="City *"
                                            sx={{ minHeight: '65px' }}
                                            value={values.City}
                                            onChange={(event: { target: { value: any; }; }) => setFieldValue("City", event.target.value)}
                                            error={touched.City && Boolean(errors.City)}
                                            helperText={touched.City && errors.City}
                                        />

                                        <Autocomplete
                                            disablePortal
                                            className={`half-width right-field`}
                                            sx={{ display: 'inline-flex', margin: "0 0 8px 0!important", minHeight: '65px' }}
                                            options={STATE_LIST}
                                            inputValue={values.State}
                                            onInputChange={(event, newInputValue) => {
                                                setFieldValue("State", newInputValue);
                                            }}

                                            renderInput={params => (
                                                <TextField
                                                    sx={{ marginLeft: '0px' }}
                                                    {...params}
                                                    className='freedom-mortgage-field mini-form'
                                                    name="State"
                                                    label="State *"
                                                    error={touched.State && Boolean(errors.State)}
                                                    helperText={touched.State && errors.State}
                                                />
                                            )}
                                        />

                                        <Box className='full-width'>
                                            <Field
                                                as={TextField}
                                                className='half-width left-field freedom-mortgage-field mini-form'
                                                name="Zip"
                                                label="Zip Code *"
                                                value={values.Zip}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("Zip", event.target.value)}
                                                error={touched.Zip && Boolean(errors.Zip)}
                                                helperText={touched.Zip && errors.Zip}
                                            />
                                        </Box>

                                        <FormControl
                                            className='full-width'
                                            error={touched.adtCustomerStatus && Boolean(errors.adtCustomerStatus)}
                                        >
                                            <Field
                                                name="adtCustomerStatus"
                                                as={RadioGroup}
                                                sx={{ marginTop: '16px' }}
                                                value={values.adtCustomerStatus}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("adtCustomerStatus", event.target.value)}
                                            >
                                                <>
                                                    <FormLabel>Please select the option that applies to you: *</FormLabel>
                                                    <FormControlLabel
                                                        value="Current"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Radio sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Current ADT Customer"
                                                    />
                                                    <FormControlLabel
                                                        value="Previous"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Radio sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Previously an ADT Customer"
                                                    />
                                                    <FormControlLabel
                                                        value="Never"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Radio sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Never an ADT Customer"
                                                    />
                                                </>
                                            </Field>
                                            <FormHelperText>{touched.adtCustomerStatus && errors.adtCustomerStatus}</FormHelperText>
                                        </FormControl>

                                        <FormControl
                                            className='full-width'
                                            error={touched.securityInterests && Boolean(errors.securityInterests)}
                                        >
                                            <FormLabel sx={{ marginTop: '16px' }} component="legend">What interests you most about home security? *</FormLabel>
                                            <Field
                                                name="securityInterests"
                                                as={FormGroup}
                                                value={values.securityInterests}
                                                onChange={(event: { target: { value: any; checked: any; }; }) => {
                                                    const value = event.target.value;
                                                    const checked = event.target.checked;

                                                    setFieldValue("securityInterests", checked
                                                        ? [...values.securityInterests, event.target.value]
                                                        : values.securityInterests.filter(item => item !== value)
                                                    );
                                                }}
                                            >
                                                <>
                                                    <FormControlLabel
                                                        value="Life Safety Device (Smoke, CO Sensors)"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Life Safety Device (Smoke, CO Sensors)"
                                                    />
                                                    <FormControlLabel
                                                        value="Doorbell camera"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Doorbell camera"
                                                    />
                                                    <FormControlLabel
                                                        value="Indoor/Outdoor cameras"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Indoor/Outdoor Cameras"
                                                    />
                                                    <FormControlLabel
                                                        value="Home Automation"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Home Automation"
                                                    />
                                                    <FormControlLabel
                                                        value="Peace of Mind"
                                                        sx={{ "& .MuiFormControlLabel-label": { font: 'normal 15px/27px SHS Body Font,sans-serif', color: '#545859' }, }}
                                                        control={
                                                            <Checkbox sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 20,
                                                                },
                                                            }} />
                                                        }
                                                        label="Peace of Mind"
                                                    />
                                                </>
                                            </Field>
                                            <FormHelperText>{touched.securityInterests && errors.securityInterests}</FormHelperText>
                                        </FormControl>

                                        <Box sx={{ marginTop: '8px' }}>
                                            <Typography sx={{ font: 'normal 12px/20px SHS Body Font,sans-serif', color: '#545859', marginBottom: '8px', marginTop: '8px' }}>Below you can add things like the best time to contact you, or anything else that you want your concierge rep to know.</Typography>
                                            <Field
                                                as={TextField}
                                                name="customerNotes"
                                                label="Comments"
                                                className="full-width freedom-mortgage-field mini-form"
                                                multiline
                                                rows={4}
                                                sx={{
                                                    '& div>textarea': {
                                                        zIndex: 99
                                                    },
                                                }}
                                                value={values.customerNotes}
                                                onChange={(event: { target: { value: any; }; }) => setFieldValue("customerNotes", event.target.value)}
                                                error={touched.customerNotes && Boolean(errors.customerNotes)}
                                                helperText={touched.customerNotes && errors.customerNotes}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography className='my-vu-form-disclaimer'>
                                                By clicking the 'Get a Free Quote' button below, I agree that a Safe Haven team member may contact me via text messages or phone calls to the phone number provided by me using automated technology about ADT offers and consent is not required to make a purchase. Your information is collected and used in accordance with our privacy policy.
                                            </Typography>
                                        </Box>

                                        <Box sx={{ textAlign: 'center' }}>
                                            <LoadingButton
                                                variant='contained'
                                                className='partner-call-btn'
                                                type="submit"
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                                sx={{
                                                    maxHeight: '56px',
                                                    minWidth: '100px',
                                                    fontSize: '16px',
                                                    textTransform: 'none',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                {isSubmitting ? 'Submitting...' : 'Submit'}
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>

            <PartnerFooter></PartnerFooter>
        </Box>
    );
} 